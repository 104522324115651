<template>
    <div class="navBar_b">
        <div class="logo">
            <router-link to='/main'>
                <img src="../../assets/images/logo/logo.png" />
            </router-link>

        </div>
        <div class="menuBar">
            <ul>
                <li><router-link to='/main'>HOME</router-link></li>
                <li><router-link to='/product'>PRODUCT</router-link></li>
                <li><router-link to='/project'>PROJECT</router-link></li>
                <li><router-link to='/about'>ABOUT</router-link></li>
                <li class="point"><router-link to='/recruit'>RECRUIT</router-link></li>
            </ul>
        </div>
    </div>
</template>

<style scoped>
    @import "../../assets/css/header_b.css";
</style>
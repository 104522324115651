<template>

    <Header />

    <!-- homeWrap start -->
    <div id="ProjectDetail_main">
        <div class="ProjectDetail_homeWrap">
            <div class="ProjectDetail_mainVisual"></div>

            <div class="ProjectDetail_titleWrap">
                <div class="title">
                    <h2>BIMPeers Projects</h2>
                    <p>
                        BIMPeer는 모바일, 웹, 데스크탑 등 모든 플렛폼을 지원하는 업무용 응용프로그램 구축을 전문적으로 해오고 있습니다.<br>
                        귀사가 웹에서 사용하실 응용프로그램 개발이 필요하시다면 BIMPeers가 도와드리겠습니다.
                    </p>
                </div>
            </div>
        </div>
        <!-- homeWrap end -->

        <nav class="statusSort">
            <ul>
                <li><a href="">진행중인 프로젝트</a></li>
                <li><a href="">완료된 프로젝트</a></li>
                <li><a href="">종료된 프로젝트</a></li>
            </ul>
        </nav>


        <div class="ProjectDetail_Div">
            <dl class="ProjectDetail_title">
                <dt class="category">WEBSITE PUBLISHING</dt>
                <dt class="client">(사)빌딩스마트협회</dt>
                <dd class="txt">빌딩스마트협회에서 정기적으로 개최하는<br>Buillding SMART Awaards 2019 공모전 웹사이트 구현</dd>
                <dd class="list">- 공모전 참가 등록 시스템</dd>
                <dd class="list">- 결제 모듈 구축</dd>
            </dl>

            <div class="ProjectDetail_information">
                <dl class="topLeft">
                    <dt class="tit">CLIENT</dt>
                    <dd class="bar"></dd>
                    <dd>(사)빌딩스마트협회</dd>
                </dl>

                <dl class="topRight">
                    <dt class="tit">STATUS</dt>
                    <dd class="bar"></dd>
                    <dd>완료</dd>
                </dl>

                <dl class="bottomLeft">
                    <dt class="tit">PROJECT DURATION</dt>
                    <dd class="bar"></dd>
                    <dd>2019.05 ~ 2019.09</dd>
                </dl>

                <dl class="bottomRight">
                    <dt class="tit">KEYWORDS</dt>
                    <dd class="bar"></dd>
                    <dd>#AutoCAD</dd>
                    <dd>#StandAlone</dd>
                    <dd>#Design Automation</dd>
                    <dd>#REVIT</dd>
                </dl>

                <dl class="members_list">
                    <dt class="tit">PROJECT MEMBERS</dt>
                    <dd class="bar"></dd>
                    <dd>BIM SOLUTION 1</dd>
                </dl>

                <div class="members_profiles">
                    <div class="thumbnails">
                        <div class="img"></div>
                        <div class="name">
                            <h2>홍길동 과장</h2>
                            <h3>Gildong, Hong</h3>
                        </div>
                    </div>

                    <div class="thumbnails">
                        <div class="img"></div>
                        <div class="name">
                            <h2>홍길동 과장</h2>
                            <h3>Gildong, Hong</h3>
                        </div>
                    </div>

                    <div class="thumbnails">
                        <div class="img"></div>
                        <div class="name">
                            <h2>홍길동 과장</h2>
                            <h3>Gildong, Hong</h3>
                        </div>
                    </div>

                    <div class="thumbnails">
                        <div class="img"></div>
                        <div class="name">
                            <h2>홍길동 과장</h2>
                            <h3>Gildong, Hong</h3>
                        </div>
                    </div>

                    <div class="thumbnails">
                        <div class="img"></div>
                        <div class="name">
                            <h2>홍길동 과장</h2>
                            <h3>Gildong, Hong</h3>
                        </div>
                    </div>

                    <div class="thumbnails">
                        <div class="img"></div>
                        <div class="name">
                            <h2>홍길동 과장</h2>
                            <h3>Gildong, Hong</h3>
                        </div>
                    </div>
                </div>

                <div class="project_thumbnails">
                    <div class="thumbnails_Left"></div>
                    <div class="thumbnails_RightTop"></div>
                    <div class="thumbnails_RightBottom"></div>
                </div>
            </div>

            <!--div class="keywordDiv">
                <h2>KEYWORD</h2>
                <ul class="keywordTable">
                    <li>#AutoCAD</li>
                    <li>#StandAlone</li>
                    <li>#Design Automation</li>
                    <li>#REVIT</li>
                </ul>
            </div-->
        </div>


        <div class="projcetFooter">
            <div>
                <img src="../assets/images/project_detail/project_detail_visualImg.jpg">
                <p>
                    BIMPeers는 수년 간 건축분야를 비롯하여 전자, 유통, 서비스 등<br>
                    많은 업체들과 프로젝트를 진행 해 왔습니다.<br><br>

                    많은 프로젝트를 통해 쌓은 풍부한 개발 경험과 기술력을 기반으로 사용자에게
                    <br>최적의 솔루션을 개발, 제공 해 드립니다.
                </p>
            </div>
        </div>
    </div>

</template>

<style>
    @import "../assets/css/reset.css";
    @import "../assets/css/projectDetail.css";
</style>



<script>
    import Header from '../../src/views/common/Header_b.vue'; //import 헤더 추가
    export default {
        name: 'App',
        components: {
            Header
        }
    }
</script>


<template>
    <footer>
        <div class="footerWrap">
            <div class="desc">
                <img src="../../assets/images/logo/logo-w.png" />
                <div class="txt">
                    <p>서울특별시 서초구 서운로 26-1, 보일빌딩 501 / 601호</p>
                    <p>TEL 070-7767-2505    FAX 070-8811-2505</p>
                    <p>E-Mail help@bimpeers.com</p>
                </div>
            </div>
        </div>
    </footer>
</template>


<style>
    @import "../../assets/css/reset.css";
    @import "../../assets/css/footer.css";
</style>
<template>

    <Header />

    <div id="Home_main">
        <div class="mainDiv">
            <div class="w-line"></div>
            <div class="h-line"></div>

            <div class="mainTitle">
                <h4>WE MAKE BETTER WORLD -</h4>
                <h2>모두가 혁신화 된 기술을 경험할 수 있는<br>세상을 위해 노력합니다</h2>
                <div class="Mainbar"></div>
                <p>
                    BIMPeers는 건축.건설분야 정보화 솔루션 제공을 목표로
                    2008년 설립된 전문 소프트웨어 개발업체입니다.<br>
                    프로젝트를 체계적이고 효율적으로 운영할 수 있도록 지원하는 Archibang,
                    BIM 라이브러리를 통합 관리 할 수 있는<br>LMS를 비롯해 건축.건설분야 업무의
                    정보화 솔루션 제공을 위해 노력하고 있습니다.
                </p>
                <a class="btn" href="project">PROJECT</a>
            </div>
        </div>

        <div class="product_Div">
            <div class="product_main">
                <div class="w-line"></div>
                <div class="h-line"></div>

                <div class="productList">
                    <div class="productTitle">
                        <div class="product_bar"></div>
                        <h2>PRODUCT</h2>
                        <p>
                            BIMPeers는 모바일, 웹, 데스크탑 등 모든 플랫폼을 지원하는
                            업무용 응용프로그램 구축을 전문적으로 해오고 있습니다.<br><br>

                            귀사가 웹에서 사용하실 응용프로그램 개발이 필요하시다면
                            BIMPeers가 도와드리겠습니다.
                        </p>

                        <a class="prev" href="#"></a>
                        <a class="next" href="#"></a>
                        <div class="product_bar"></div>

                        <div class="slideBtn">
                            <div class="prev"></div>
                            <div class="next"></div>
                        </div>
                    </div>

                    <div class="slideDiv">
                        <div class="slideList">
                            <img src="../assets/images/mainPage/home_product01.jpg" alt="Alternate Text" />
                            <div class="opacity"></div>
                            <div class="slideTxt">
                                <h2>I-VIEW</h2>
                                <p>VIEW MORE</p>
                            </div>
                        </div>

                        <div class="slideList">
                            <img src="../assets/images/mainPage/home_product02.jpg" alt="Alternate Text" />
                            <div class="opacity"></div>
                            <div class="slideTxt">
                                <h2>WEB-VIEW</h2>
                                <p>VIEW MORE</p>
                            </div>
                        </div>

                        <div class="slideList">
                            <img src="../assets/images/mainPage/home_product03.jpg" alt="Alternate Text" />
                            <div class="opacity"></div>
                            <div class="slideTxt">
                                <h2>WEB-VIEW</h2>
                                <p>VIEW MORE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="project_Div">
            <div class="project_Main">
                <div class="w-line"></div>
                <div class="h-line"></div>
            </div>

            <div class="project_Title">
                <h2>BUSINESS PROJECTS</h2>
                <p>
                    소프트웨어라고 하면 Desktop에 설치하여 사용하는 것만을 떠올리던 과거와 달리 최근에는
                    Desktop, Mobile, Web 등의 다양한 플랫폼을 생각할 수 있습니다. 멀티 플랫폼 지원을 위해서는
                    다양한 프로그래밍 기술과 사용자 인터페이스와 사용자 경험의 디자인 능력이 필요합니다.<br /><br />

                    BIMPeer는 Desktop, Mobile, Web 모든 플랫폼을 아우를 수 있는 기술력과 풍부한 개발 경험을 기반으로
                    사용자에게 최적화된 솔루션을 제작 해 드릴 것을 약속 드립니다.
                </p>
            </div>

            <div class="project_list_table">
                <dl class="projectList">
                    <dd class="project_top"></dd>
                    <dd class="status">진행중인 프로젝트</dd>

                    <dt class="project_name">BIM Collaboration Systems</dt>
                    <dd>기간 : 각 현장별 서비스 형태 제공</dd>
                    <dd>내용 : 건설현장에서 현장실무자들과 설계 협력업체 담당자들 사이의 BIM 데이터 공유를 위한 웹 협업시스템</dd>
                    <dd>특징 : BIM 협업시스템 - 웹 기반의 BIM 작업 파일 관리 시스템 | 사용자에 따른 데이터 관리 권한 제공</dd>
                    <a href="" class="list_btn">OVERVIEW</a>
                </dl>

                <dl class="projectList">
                    <dd class="project_top"></dd>
                    <dd class="status">진행중인 프로젝트</dd>

                    <dt class="project_name">BIM Collaboration Systems</dt>
                    <dd>기간 : 각 현장별 서비스 형태 제공</dd>
                    <dd>내용 : 건설현장에서 현장실무자들과 설계 협력업체 담당자들 사이의 BIM 데이터 공유를 위한 웹 협업시스템</dd>
                    <dd>특징 : BIM 협업시스템 - 웹 기반의 BIM 작업 파일 관리 시스템 | 사용자에 따른 데이터 관리 권한 제공</dd>
                    <a href="" class="list_btn">OVERVIEW</a>
                </dl>

                <dl class="projectList">
                    <dd class="project_top"></dd>
                    <dd class="status">진행중인 프로젝트</dd>

                    <dt class="project_name">BIM Collaboration Systems</dt>
                    <dd>기간 : 각 현장별 서비스 형태 제공</dd>
                    <dd>내용 : 건설현장에서 현장실무자들과 설계 협력업체 담당자들 사이의 BIM 데이터 공유를 위한 웹 협업시스템</dd>
                    <dd>특징 : BIM 협업시스템 - 웹 기반의 BIM 작업 파일 관리 시스템 | 사용자에 따른 데이터 관리 권한 제공</dd>
                    <a href="" class="list_btn">OVERVIEW</a>
                </dl>
            </div>
        </div>

        <div class="newsDiv">
            <div class="news_Main">
                <div class="w-line"></div>
                <div class="h-line"></div>
            </div>

            <div class="newsTitle">
                <h2>NEWS</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, gravida senectus tincidunt non dui</p>
            </div>

            <div class="news_list">
                <div class="news_contents">
                    <div class="news_image"></div>
                    <div class="news_txt">
                        <h2>NEWS 01</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                </div>

                <div class="news_contents">
                    <div class="news_image"></div>
                    <div class="news_txt">
                        <h2>NEWS 02</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                </div>

                <div class="news_contents">
                    <div class="news_image"></div>
                    <div class="news_txt">
                        <h2>NEWS 03</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                </div>

                <div class="news_contents">
                    <div class="news_image"></div>
                    <div class="news_txt">
                        <h2>NEWS 04</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    @import "../assets/css/main.css?ver=1";
    @import "../assets/css/reset.css?ver=1";
</style>

<script>
    import Header from '../../src/views/common/Header.vue'; //import 헤더 추가
    export default {
        name: 'App',
        components: {
            Header
        }
    }

</script>



<template>

    <Header />

    <div id="RecruitList_main">
        <div class="RecruitList_Div">
            <div class="RecruitList_Title">
                <h2>Find Your Next Career</h2>
                <h3>
                    우리는‘금융의 모든 문제를 해결하겠다’는 목표에 마음이 움직여 여기에 모였습니다.<br />
                    토스커뮤니티는 모두가 같은 목표를 향해 달립니다. 불가능한 것을 꿈꾸고,
                    그걸 가능하게 만드는 동료들과 함께 열정적으로 일하고 있습니다.<br />
                    가슴이 설레시나요? 지금이 바로 토스커뮤니티에 합류할 때입니다.
                </h3>
            </div>

        </div>
        <nav class="RecruitList_Sort">
            <ul>
                <li><a href="">ALL</a></li>
                <li><a href="">DEVELOPEMENT</a></li>
                <li><a href="">ENGINEERING</a></li>
                <li><a href="">MANAGEMENT</a></li>
            </ul>
        </nav>

        <h2 class="comment">N개의 채용공고가 있습니다.</h2>
        <table class="careerlist_table">
            <tr class="careers">
                <div class="newBox">NEW</div>
                <td>SOFTWARE Develope Engineer</td>
                <td class="category">신입 | C# | .net | C++ | JAVA | Python </td>

                <div class="newBox">NEW</div>
                <td>Software Developement Planner </td>
                <td class="category">신입 · 경력 | AutoCAD | Revit Add-In | BIM</td>

                <div class="newBox">NEW</div>
                <td>WEB System Developement Engineer </td>
                <td class="category">경력 | .net | C# | JAVA | Spring | Vue.js</td>

                <div class="newBox">NEW</div>
                <td>SOFTWARE Develope Engineer</td>
                <td class="category">신입 | C# | .net | C++ | JAVA | Python </td>
                <td>BIM Program Developement Planner</td>
                <td class="category">신입 · 경력 | Revit Add-In | BIM</td>
            </tr>
        </table>

        <table class="careerlist_table">
            <tr class="careers_date">
                <td>2022.10.06 ~ 2022.10.31</td>
                <td>2022.09.16 ~ 2022.10.03</td>
                <td>2022.09.16 ~ 2022.10.03</td>
                <td>2022.09.16 ~ 2022.10.03</td>
                <td>2022.08.30 ~ 2022.09.15</td>
            </tr>
        </table>
    </div>

</template>

<style>
    @import "../assets/css/RecruitList.css";
    @import "../assets/css/reset.css";
</style>

<script>
    import Header from '../../src/views/common/Header_b.vue'; //import 헤더 추가
    export default {
        name: 'App',
        components: {
            Header
        }
    }
</script>

<template>

    <Header />


    <div id="Project_main">
        <!-- homeWrap start -->
        <div class="project_homeWrap">
            <div class="project_main">
                <div class="projectTitle">
                    <div class="title">
                        <h2>BIMPeers Projects</h2>
                        <p>
                            BIMPeers는 계속해서 새로운 길을 만들어 가고 있습니다.<br>
                            겉으로 보이는 화려함을 추구하기 보다는 내실 있는 회사가 되도록 노력하겠습니다.
                        </p>
                    </div>
                </div>
            </div>

        </div>
        <!-- homeWrap end -->


        <nav class="projectSort">
            <ul>
                <li><a href="">연도별</a></li>
                <li><a href="">고객사</a></li>
                <li><a href="">키워드</a></li>
            </ul>
        </nav>

        <div class="projectDiv">
            <h2>2016</h2>

            <table class="projectTable">
                <tr class="project_left">
                    <td class="project_name">
                        <router-link to='/projectDetail'>BIM 구조모델 및 구조도면 자동화 설계프로그램 개발</router-link>
                        <div class="statusBox">진행중</div>
                    </td>
                    <td class="project_year_client"><p>(주)삼우종합건축사사무소 | 2016.12 ~ 2016.12</p></td>
                    <td class="tags">
                        <a href="#">AutoCAD | </a>
                        <a href="#">StandAlone | </a>
                        <a href="#">Design Automation | </a>
                        <a href="#">REVIT</a>
                    </td>
                </tr>

                <tr class="project_right">
                    <td class="project_name">
                        <router-link to='/projectDetail'>BIM 구조모델 및 구조도면 자동화 설계프로그램 개발</router-link>
                        <div class="statusBox">진행중</div>
                    </td>
                    <td class="project_year_client"><p>(주)삼우종합건축사사무소 | 2016.12 ~ 2016.12</p></td>
                    <td class="tags">
                        <a href="#">AutoCAD | </a>
                        <a href="#">StandAlone | </a>
                        <a href="#">Design Automation | </a>
                        <a href="#">REVIT</a>
                    </td>
                </tr>

                <tr class="project_left">
                    <td class="project_name">
                        <router-link to='/projectDetail'>BIM 구조모델 및 구조도면 자동화 설계프로그램 개발</router-link>
                        <div class="statusBox">진행중</div>
                    </td>
                    <td class="project_year_client"><p>(주)삼우종합건축사사무소 | 2016.12 ~ 2016.12</p></td>
                    <td class="tags">
                        <a href="#">AutoCAD | </a>
                        <a href="#">StandAlone | </a>
                        <a href="#">Design Automation | </a>
                        <a href="#">REVIT</a>
                    </td>
                </tr>

                <tr class="project_right">
                    <td class="project_name">
                        <router-link to='/projectDetail'>BIM 구조모델 및 구조도면 자동화 설계프로그램 개발</router-link>
                        <div class="statusBox">진행중</div>
                    </td>
                    <td class="project_year_client"><p>(주)삼우종합건축사사무소 | 2016.12 ~ 2016.12</p></td>
                    <td class="tags">
                        <a href="#">AutoCAD | </a>
                        <a href="#">StandAlone | </a>
                        <a href="#">Design Automation | </a>
                        <a href="#">REVIT</a>
                    </td>
                </tr>

                <tr class="project_left">
                    <td class="project_name">
                        <router-link to='/projectDetail'>BIM 구조모델 및 구조도면 자동화 설계프로그램 개발</router-link>
                        <div class="statusBox">진행중</div>
                    </td>
                    <td class="project_year_client"><p>(주)삼우종합건축사사무소 | 2016.12 ~ 2016.12</p></td>
                    <td class="tags">
                        <a href="#">AutoCAD | </a>
                        <a href="#">StandAlone | </a>
                        <a href="#">Design Automation | </a>
                        <a href="#">REVIT</a>
                    </td>
                </tr>

                <tr class="project_right">
                    <td class="project_name">
                        <router-link to='/projectDetail'>BIM 구조모델 및 구조도면 자동화 설계프로그램 개발</router-link>
                        <div class="statusBox">진행중</div>
                    </td>
                    <td class="project_year_client"><p>(주)삼우종합건축사사무소</p></td>
                    <td class="tags">
                        <a href="#">AutoCAD | </a>
                        <a href="#">StandAlone | </a>
                        <a href="#">Design Automation | </a>
                        <a href="#">REVIT</a>
                    </td>
                </tr>

            </table>
        </div>

        <div class="projcetFooter">
            <div>
                <img src="../assets/images/project/project_visualImg.jpg" />
            </div>
        </div>
    </div>
</template>

<style Scoped>
    @import "../assets/css/reset.css";
    @import "../assets/css/project.css";
</style>



<script>
    import Header from '../../src/views/common/Header_b.vue'; //import 헤더 추가
    export default {
        name: 'App',
        components: {
            Header
        }
    }
</script>




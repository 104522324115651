<template>
    
    <router-view />

    <Footer />

</template>

<script>
    import Footer from '../src/views/common/Footer.vue';
    export default {
        name: 'App',
        components: {
            Footer
        }
    }
</script>




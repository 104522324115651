<template>

    <Header />

       <div id="Recruit_main">
            <div class="recruitDiv">
                <div class="opacity">
                    <div class="recruitTitle">
                        <h4>To the world of infinite possibilities</h4>
                        <h2>We meet the Future</h2>
                        <div class="recruit_button"><a href="#">채용정보 바로가기 ></a></div>
                    </div>
                </div>
            </div>

            <div class="teamsDiv">
                <div class="teamsTitle">
                    <h2>Teams</h2>
                    <p>서로 다른 사람들이 모여 한 방향으로 나아갑니다</p>
                </div>

                <table class="teams_table">
                    <tr class="teams_left">
                        <td>BIM SOLUTION<div class="circle"></div></td>
                        <td>WEB SOLUTION</td>
                        <td class="hover">LAB</td>
                        <td>MANAGEMENT</td>
                    </tr>

                    <tr class="teams_right">
                        <td>Loren Ipsum sit amet</td>
                        <td>Loren Ipsum sit amet</td>
                        <td>Loren Ipsum sit amet</td>
                        <td>Loren Ipsum sit amet</td>
                    </tr>
                </table>

            </div>

            <div class="peopleDiv">
                <div class="peopleTitle_image"></div>
                <div class="peopleTitle">
                    <h2>People</h2>
                    <h3>성장을 위한 가장 확실한 방법은 뛰어난 동료들 사이에 있는 것 입니다.</h3>
                </div>

                <div class="peopleText">
                    <h2>인재상</h2>
                    <table class="peopleTable">
                        <tr class="people_Left">
                            <td class="title">새로운 가능성에 도전하는 인재</td>
                            <td>
                                창조적 정신과 도전정신을 기반으로 하여 미래 정보와 시대를 앞서나가며,<br />
                                현재에 대한 만족보다 미래의 발전을 고민하고, 항상 새로운 것을 추구하는 자세를 가집니다.
                            </td>

                            <td class="title">신뢰와 존중을 기반한 협동과 소통</td>
                            <td>
                                깊은 생각에서 나오는 행동, 퀄리티와 일정에 대한 책임감, 긍정적 언행은<br />
                                동료 간의 신뢰를 두텁게 합니다. 신로와 존중을 바탕으로 팀워크를 발휘하여 문제를 해결합니다.
                            </td>
                        </tr>


                        <tr class="people_Right">
                            <td class="title">사용자 관점의 공헌과 성과</td>
                            <td>
                                우리의 아이디어, 지향점이 사용자의 니즈에 부합하게 설계하는 지 항상 돌아보고 일치시킵니다.<br />
                                사용자의 니즈를 분석하고, 니즈를 충족시킬 참신한 아이디어를 항상 생각합니다.
                            </td>

                            <td class="title">책임과 신뢰</td>
                            <td>
                                조직 구조에서 상호 신로의 필요성은 절대적입니다. 원활한 커뮤니케이션을 바탕으로<br />
                                조직 내 신뢰와 공동체 문화를 형성하고, 구성원 간 서로 존중하는 태도를 지향합니다.
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="bimpeersDiv">
                <div class="bimpeersTitle">
                    <h2><strong>일하기 즐거운</strong> BIMPeers</h2>
                    <h3>BIMPeers는 건강한 업무환경, 지속적인 육성과 성장, 가족과 함께 누리는 헤택을 중시합니다.</h3>
                </div>

                <div class="ContentsDiv">
                    <div class="content_List">
                        <img src="../assets/images/icons/recruit_icon_01.png">
                        <h3>자율출퇴근제</h3>
                        <p>
                            <strong>AM 07:00 ~ PM 12:30</strong> 자율 출근,<br />
                            <strong>PM 04:00 ~  PM 08:30</strong> 자율 퇴근으로 유연한 업무가 가능합니다.
                        </p>
                    </div>

                    <div class="content_List">
                        <img src="../assets/images/icons/recruit_icon_02.png">
                        <h3>자율 연차, 반차 사용 및 자율복장</h3>
                        <p>
                            원하는 날짜, 원하는 시간대에 맞춰 <strong>자유롭게 연차, 반차 사용이</strong><br />
                            가능하며, 복장 또한 원하는 대로 가능합니다.
                        </p>
                    </div>

                    <div class="content_List">
                        <img src="../assets/images/icons/recruit_icon_03.png">
                        <h3>매월 마지막 주 금요일 오전근무</h3>
                        <p>
                            매월 마지막 주 금요일은 <strong> 오전근무</strong> (4h) 를 하여<br />
                            직원들의 Refresh가 가능합니다.
                        </p>
                    </div>

                    <div class="content_List">
                        <img src="../assets/images/icons/recruit_icon_04.png">
                        <h3>전공 서적 제공</h3>
                        <p>
                            직원들의 스킬업 향상에 필요한 <strong>각종 전공 서적 대여</strong> 가능합니다.
                        </p>
                    </div>

                    <div class="content_List">
                        <img src="../assets/images/icons/recruit_icon_05.png">
                        <h3>신입사원 청년내일채움공제</h3>
                        <p>
                            직원들의 밝은 내일을 위한 <strong>청년내일채움공제</strong>를 시행합니다.
                        </p>
                    </div>

                    <div class="content_List">
                        <img src="../assets/images/icons/recruit_icon_06.png">
                        <h3>생일 해당 월 자율 반차 제공</h3>
                        <p>
                            생일 해당 월에 원하는 날짜에 자유롭게 <strong>반차</strong>가 제공됩니다.<br />
                            오전 / 오후 반차 중 원하는 시간대로 사용하실 수 있습니다.
                        </p>
                    </div>

                </div>
            </div>

            <div class="members_Div">
                <div class="membersTitle">
                    <h2>BIMPeers Members</h2>
                    <h3>함께 일하고 싶은 동료, 빔피어스 사람들을 만나보세요.</h3>
                </div>

                <ul class="members_List">
                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">김  경 대표</p>
                                <p class="team">BIMPeers</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">이 순 정 소장</p>
                                <p class="team">연구소</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">박 준 혁 차장</p>
                                <p class="team">BIM 솔루션 1팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">김 대 진 과장</p>
                                <p class="team">연구소</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">김 용 하 팀장</p>
                                <p class="team">전략기획팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">김 현 선 과장</p>
                                <p class="team">경영지원팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">장 재 림 과장</p>
                                <p class="team">BIM 솔루션 2팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">심 우 찬 대리</p>
                                <p class="team">BIM 솔루션 1팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">조 동 근 대리</p>
                                <p class="team">연구</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">이 다 희 주임</p>
                                <p class="team">BIM 솔루션 2팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">홍 지 희 주임</p>
                                <p class="team">경영지원팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">강 승 지 사원</p>
                                <p class="team">WEB 솔루션팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">김 혜 선 사원</p>
                                <p class="team">WEB 솔루션팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">류 수 형 사원</p>
                                <p class="team">BIM 솔루션 1팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">박 승 준 사원</p>
                                <p class="team">BIM 솔루션 1팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">백 승 민 사원</p>
                                <p class="team">BIM 솔루션 2팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">백 승 민 사원</p>
                                <p class="team">BIM 솔루션 2팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">이 승 민 사원</p>
                                <p class="team">전략기획팀</p>
                            </div>
                        </div>
                    </li>



                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">한 예 린 사원</p>
                                <p class="team">WEB 솔루션팀</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="BottomDiv">
                <div class="Bottom_txt">
                    <h2>APPLY</h2>
                    <h3>BIMPeers와 함께 할 멋진 동료를 찾고 있습니다.</h3>
                    <p>
                        BIMPeers는 모두가 같은 목표를 향해 달립니다. 불가능한 것을 꿈꾸고, 그걸 가능하게 만드는<br />
                        동료들과 함께 열정적으로 일하고 있습니다. 가슴이 설레시나요? 지금이 바로 BIMPeers와 합류할 때 입니다.
                    </p>

                    <a href="#">채용정보 보기 ></a>
                </div>
            </div>

        </div>
</template>

<style>
    @import "../assets/css/Recruit.css";
    @import "../assets/css/reset.css";
</style>

<script>
    import Header from '../../src/views/common/Header.vue'; //import 헤더 추가
    export default {
        name: 'App',
        components: {
            Header
        }
    }
</script>

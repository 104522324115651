<template>

    <Header />

    <div id="main">
        <div class="aboutDiv">
            <div class="aboutBar">
                <ul>
                    <li><a>MISION | VISION | CORE-VALUE</a></li>
                    <li><a>MEMBERS</a></li>
                    <li><a>HISTORY</a></li>
                    <li><a>CONTACT</a></li>
                </ul>
            </div>

            <div class="aboutTitle">
                <div class="title">
                    <h2>WE MAKE BETTER WORLD</h2>
                    <p>
                        모두가 혁신화 된 기술을 경험할 수 있는 세상을 위해 노력합니다.
                    </p>
                </div>
            </div>

            <div class="aboutImages">
                <div class="dashLineT">
                    <div class="arrow"></div>

                    <dl class="aboutImages_title">
                        <dt>MISSION</dt>
                        <dd class="ko">IT 기술을 활용한 더 나은 건설환경 구축</dd>
                        <dd class="en">Building a Better Construction Environment Using IT Technology</dd>
                    </dl>

                    <div class="img_left"></div>
                </div>

                <div class="dashLineM">
                    <dl class="aboutImages_title">
                        <dt>VISION</dt>
                        <dd class="ko">건설 디지털 데이터 처리를 위한<br> BIM 기반 통합 플랫폼 구축</dd>
                        <dd class="en">Building BIM-based Integrated Platform for<br>Digital Data Processing in Construction</dd>
                    </dl>

                    <div class="img_right"></div>
                </div>

                <div class="dashLineB">
                    <dl class="aboutImages_title">
                        <dt>CORE - VALUE</dt>
                        <dd class="ko">기술선도 | 변화 선도 | 품질경영 | 인간존중</dd>
                        <dd class="en">
                            Technical Leadership | Leadership of Change<br>
                            Quality management | Respect for human beings
                        </dd>
                    </dl>

                    <table class="iconDiv">
                        <tr class="iconImages">
                            <td>
                                <img src="../assets/images/about/about_corevalue_simg1.jpg" />
                            </td>
                            <td><img src="../assets/images/about/about_corevalue_simg2.jpg"></td>
                            <td><img src="../assets/images/about/about_corevalue_simg3.jpg"></td>
                            <td><img src="../assets/images/about/about_corevalue_simg4.png"></td>
                        </tr>
                    </table>

                    <div class="Limg2"></div>
                </div>
            </div>

            <div class="membersDiv">
                <div class="members_title">
                    <h4>BIMPeers 임직원</h4>
                    <h2>MEMBERS</h2>
                </div>

                <ul class="thumbnail_List">
                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">김  경 대표</p>
                                <p class="team">BIMPeers</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">이 순 정 소장</p>
                                <p class="team">연구소</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">박 준 혁 차장</p>
                                <p class="team">BIM 솔루션 1팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">김 대 진 과장</p>
                                <p class="team">연구소</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">김 용 하 팀장</p>
                                <p class="team">전략기획팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">김 현 선 과장</p>
                                <p class="team">경영지원팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">장 재 림 과장</p>
                                <p class="team">BIM 솔루션 2팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">심 우 찬 대리</p>
                                <p class="team">BIM 솔루션 1팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">조 동 근 대리</p>
                                <p class="team">연구</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">이 다 희 주임</p>
                                <p class="team">BIM 솔루션 2팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">홍 지 희 주임</p>
                                <p class="team">경영지원팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">강 승 지 사원</p>
                                <p class="team">WEB 솔루션팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">김 혜 선 사원</p>
                                <p class="team">WEB 솔루션팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">류 수 형 사원</p>
                                <p class="team">BIM 솔루션 1팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">박 승 준 사원</p>
                                <p class="team">BIM 솔루션 1팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">백 승 민 사원</p>
                                <p class="team">BIM 솔루션 2팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">백 승 민 사원</p>
                                <p class="team">BIM 솔루션 2팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">이 승 민 사원</p>
                                <p class="team">전략기획팀</p>
                            </div>
                        </div>
                    </li>

                    <li class="thumbnail_images">
                        <div class="profile">
                            <!--<div class="profile_images"><img src="../assets/images/profile/김경_대표님.jfif" alt="profileImg" /></div>-->
                            <div class="profile_text">
                                <p class="name">한 예 린 사원</p>
                                <p class="team">WEB 솔루션팀</p>
                            </div>
                        </div>
                    </li>

                </ul>
            </div>

            <div class="historyDiv">
                <div class="history_title">
                    <h4>회사연혁</h4>
                    <h2>HISTORY</h2>
                    <p>
                        BIMPeers는 건축.건설분야 정보화 솔루션 제공을 목표로 2008년 설립된 전문 소프트웨어 개발 업체입니다.
                        프로젝트를 체계적이고 효율적으로 운영할 수 있도록 지원하는 Archibang TM , BIM 라이브러리를
                        통합 관리 할 수 있는 LMS TM 를 비롯해 건축.건설분야 업무의 정보화 솔루션 제공을 위해 노력하고 있습니다.
                    </p>

                    <div class="history_source">
                        <ul>
                            <li class="bar"></li>
                            <li class="dot"></li>
                            <li class="history_year">2016 ~ NOW</li>
                            <li class="history_year">2014</li>
                            <li class="history_year">2010</li>
                            <li class="history_year">2008</li>
                        </ul>

                        <div class="dot"></div>

                        <div class="history_graph">
                            <ul class="history_right">
                                <li class="text">
                                    <h2 class="year">2016</h2>
                                    <div class="historyBar"></div>
                                    <strong>05</strong><p>XENOPAN(대한민국 대표 프로젝트 메신저) 개발</p>
                                    <strong>01</strong><p>Revit 기반 라이브러리 관리 시스템(LMS) 서비스 시작</p>
                                </li>

                                <li class="text">
                                    <h2 class="year">2016</h2>
                                    <div class="historyBar"></div>
                                    <strong>05</strong><p>XENOPAN(대한민국 대표 프로젝트 메신저) 개발</p>
                                    <strong>01</strong><p>Revit 기반 라이브러리 관리 시스템(LMS) 서비스 시작</p>
                                </li>
                            </ul>

                            <ul class="history_left">
                                <li class="text">
                                    <h2 class="year">2014</h2>
                                    <div class="historyBar"></div>
                                    <strong>05</strong><p>XENOPAN(대한민국 대표 프로젝트 메신저) 개발</p>
                                    <strong>01</strong><p>Revit 기반 라이브러리 관리 시스템(LMS) 서비스 시작</p>
                                </li>

                                <li class="text">
                                    <h2 class="year">2014</h2>
                                    <div class="historyBar"></div>
                                    <strong>05</strong><p>XENOPAN(대한민국 대표 프로젝트 메신저) 개발</p>
                                    <strong>01</strong><p>Revit 기반 라이브러리 관리 시스템(LMS) 서비스 시작</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="history_image">
                    <img src="../assets/images/about/about_historyWrap.png" alt="Alternate Text" />
                </div>
            </div>

            <div class="contactDiv">
                <div class="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12663.637957366878!2d127.0294945!3d37.4864622!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe9720f804bd4d43!2zQklNUGVlcnMo67mU7ZS87Ja07IqkKQ!5e0!3m2!1sko!2skr!4v1664179747136!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <div class="address">

                    <table class="address_table">
                        <tr class="logo"><img src="../assets/images/logo/logo.png" /></tr>
                        <tr class="tel_fax">
                            <td>TEL | 070-7767-2505</td>
                            <td>FAX | 070-8811-2505</td>
                        </tr>

                        <tr>
                            <td>MAIL help@bimpeers.com</td>
                        </tr>

                        <tr>
                            <td>ADDRESS 서울특별시 서초구 서운로 26-1, 보일빌딩 501, 601호</td>
                        </tr>


                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    @import "../assets/css/about.css";
    @import "../assets/css/reset.css";
</style>

<script>
    import Header from '../../src/views/common/Header.vue'; //import 헤더 추가
    export default {
        name: 'App',
        components: {
            Header
        }
    }
</script>


<template>

    <Header />


    <div id="main">
        <div class="product_homeWrap">
            <div class="product_main">

                <div class="productTitle">
                    <div class="title">
                        <h2>BIMPeers Products</h2>
                        <p>
                            BIMPeer는 모바일, 웹, 데스크탑 등 모든 플렛폼을 지원하는 업무용 응용프로그램 구축을 전문적으로 해오고 있습니다.<br>
                            귀사가 웹에서 사용하실 응용프로그램 개발이 필요하시다면 BIMPeers가 도와드리겠습니다.
                        </p>

                        <div class="contactBox">
                            <h4>제품 및 커스터마이징 문의</h4>


                            <ul class="contactMethod">
                                <li>
                                    <img src="../assets/images/icons/product_icon_tel.png" />
                                </li>
                                <li>070-7767-2505</li>
                                <li>
                                    <img src="../assets/images/icons/product_icon_mail.png" />
                                </li>
                                <li>070-8811-2505</li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- homeWrap end -->

        <nav class="productBar">
            <ul>
                <li><a href="">I-VIEW</a></li>
                <li><a href="">WEB-VIEW</a></li>
                <li><a href="">LMS</a></li>
                <li><a href="">PMIS</a></li>
                <div class="barB"></div>
            </ul>
        </nav>

        <div class="productDiv">
            <div class="IVWrap">
                <div class="I-VIEW">
                    <h2>I-VIEW</h2>
                    <p>
                        LMS는 카테고리 기반의 BIM라이브러리 관리 기능을 제공하며
                        실무에서 라이브러리를 효율적으로 검색 해 사용할 수 있도록 도와주는
                        통합 BIM라이브러리 관리 시스템입니다.<br><br>

                        라이브러리의 형상 미리 보기를 비롯하여 다양한 검색방법과 속성정보 확인 등의
                        기능은 실무자들에게 BIM라이브러리를 쉽고 빠르게 프로젝트에 적용할 수 있는
                        환경을 제공합니다.
                    </p>
                </div>

                <div class="function">
                    <div class="titleBox">
                        <h3>MAIN FUNCTION</h3>
                    </div>

                    <ul>
                        <li>- 라이브러리 통합검색 – 이름 / 건설전문분야 / 레이어 / 세부분류 등으로 검색가능</li>
                        <li>- 라이브러리 리스트 저장 기능</li>
                        <li>- 라이브러리를 Excel로 출력하여 관리하는 기능</li>
                        <li>- 개인 라이브러리 폴더 관리 기능</li>
                        <li>- Revit Import – 라이브러리 파일을 바로 Project에 등록하는 기능</li>
                        <li>- Property – 속성 정보와 사용자 추가 정보를 제공</li>
                        <li>- 3차원 형상 정보 - 라이브러리 3차원 형상 정보 확인 기능</li>
                    </ul>
                </div>

                <div class="thumbImg"></div>
            </div>

            <div class="WvWrap">
                <div class="WEB-VIEW">
                    <h2>WEB - VIEW</h2>
                    <p>
                        Autodesk Revit에서 추출한 건축모델을 웹 브라우저상에서 보여주는
                        웹 Viewer입니다.<br>
                        프로그램 설치 없이 Desktop, Mobile 에서 손쉽게 볼 수 있고,
                        마우스 클릭만으로 오브젝트 속성을 확인 할 수 있습니다.<br><br>

                        또한 보편적으로 쓰이는 조작방법을 그대로 따라 BIM의 강력하고 간편한 뷰 환경을 제공합니다.
                    </p>
                </div>

                <div class="function">
                    <div class="titleBox">
                        <h3>MAIN FUNCTION</h3>
                    </div>

                    <ul>
                        <li>- 오브젝트별 분리, 타입별 분리 로드</li>
                        <li>- 이동, 확대/축소, 회전, 전/후방 이동 등 다양한 카메라 전환</li>
                        <li>- Revit 모델 변환 Plugin</li>
                        <li>- Shadow, Antialias, Alpha, Texture, Shader 기능</li>
                        <li>- 데스크탑 브라우저 - Chrome, Firefox, IE11 지원</li>
                        <li>- 모바일 브라우저 - Chrome, Firefox 지원</li>
                    </ul>
                </div>

                <div class="thumbImg"></div>
            </div>

            <div class="LMSWrap">
                <div class="LMS">
                    <h2>LMS <small>(Library Management System)</small></h2>
                    <p>
                        LMS는 카테고리 기반의 BIM라이브러리 관리 기능을 제공하며
                        실무에서 라이브러리를<br>효율적으로 검색 해 사용할 수 있도록 도와주는
                        통합 BIM라이브러리 관리 시스템입니다.<br><br>

                        라이브러리의 형상 미리 보기를 비롯하여 다양한 검색방법과 속성정보 확인 등의
                        기능은 실무자들에게 BIM라이브러리를 쉽고 빠르게 프로젝트에 적용할 수 있는
                        환경을 제공합니다.
                    </p>
                </div>

                <div class="function">
                    <div class="titleBox">
                        <h3>MAIN FUNCTION</h3>
                    </div>

                    <ul>
                        <li>- 라이브러리 통합검색 – 이름 / 건설전문분야 / 레이어 / 세부분류 등으로 검색가능</li>
                        <li>- 라이브러리 리스트 저장 기능</li>
                        <li>- 라이브러리를 Excel로 출력하여 관리하는 기능</li>
                        <li>- 개인 라이브러리 폴더 관리 기능</li>
                        <li>- Revit Import – 라이브러리 파일을 바로 Project에 등록하는 기능</li>
                        <li>- Property – 속성 정보와 사용자 추가 정보를 제공</li>
                        <li>- 3차원 형상 정보 - 라이브러리 3차원 형상 정보 확인 기능</li>
                    </ul>
                </div>

                <div class="thumbImg"></div>
            </div>

            <div class="PSWrap">
                <div class="PMIS">
                    <h2>PMIS</h2>
                    <p>
                        LMS는 카테고리 기반의 BIM라이브러리 관리 기능을 제공하며
                        실무에서 라이브러리를<br>효율적으로 검색 해 사용할 수 있도록 도와주는
                        통합 BIM라이브러리 관리 시스템입니다.<br><br>

                        라이브러리의 형상 미리 보기를 비롯하여 다양한 검색방법과 속성정보 확인 등의
                        기능은 실무자들에게 BIM라이브러리를 쉽고 빠르게 프로젝트에 적용할 수 있는
                        환경을 제공합니다.
                    </p>
                </div>

                <div class="function">
                    <div class="titleBox">
                        <h3>MAIN FUNCTION</h3>
                    </div>

                    <ul>
                        <li>- 라이브러리 통합검색 – 이름 / 건설전문분야 / 레이어 / 세부분류 등으로 검색가능</li>
                        <li>- 라이브러리 리스트 저장 기능</li>
                        <li>- 라이브러리를 Excel로 출력하여 관리하는 기능</li>
                        <li>- 개인 라이브러리 폴더 관리 기능</li>
                        <li>- Revit Import – 라이브러리 파일을 바로 Project에 등록하는 기능</li>
                        <li>- Property – 속성 정보와 사용자 추가 정보를 제공</li>
                        <li>- 3차원 형상 정보 - 라이브러리 3차원 형상 정보 확인 기능</li>
                    </ul>
                </div>

                <div class="thumbImg"></div>
            </div>
        </div>

        <div class="productVisualImg">
            <p>
                BIMPeers는 고객이 필요한 ‘최적의 솔루션’ 을 제작해 드립니다.<br>
                Desktop, Mobile, Web 어느 플랫폼에서도 작동될 수 있는 통합 솔루션을 제작할 수 있습니다.
            </p>
        </div>

    </div>
</template>

<style scoped>
    @import "../assets/css/reset.css";
    @import "../assets/css/product.css";
</style>

<script>
    import Header from '../../src/views/common/Header_b.vue'; //import 헤더 추가
    export default {
        name: 'App',
        components: {
            Header
        }
    }
</script>



